import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BtnPrimary from "../components/buttons/primary"
import BtnSecondary from "../components/buttons/secondary"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div>
      </div>
      <section className="section sectionFirst">
        <div className="container py-10">
          <h2
            id="featured-models"
            className="text-2xl lg:text-3xl xl:text-4xl uppercase font-light"
          >
            SUNTEC CITY, WEST ATRIUM{" "}
            <span className="whitespace-nowrap">• 17 – 23 OCT</span>{" "}
            <span className="whitespace-nowrap">• 10AM – 10PM</span>
          </h2>
          <span class="border-t border-neutral-400 block w-full my-3"></span>
          <p className="mb-5">
            As Singapore’s newest authorised BMW dealer, we are thrilled to
            bring you an exciting line-up of BMW cars at Suntec City – West
            Atrium. Take advantage of this opportunity to upgrade to your dream
            BMW with roadshow-only exclusive deals. Drive the difference with
            Eurokars Auto.
          </p>

          <h3 className="font-bold">
            Purchase selected BMW models and enjoy the first year of ownership
            on us.*
          </h3>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 my-8">
            <Link
              to="#featured-models"
              data-gtm-category="Offers_Suntec"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Register your interest"
            >
              <BtnPrimary cta="Register your interest" />
            </Link>
          </div>

          <h2
            id="featured-models"
            className="text-2xl lg:text-3xl xl:text-4xl uppercase font-light mt-12 lg:pt-8"
          >
            EXPERIENCE JOY.
          </h2>
          <span class="border-t border-neutral-400 block w-full my-3"></span>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 md:gap-x-5 gap-y-8 md:gap-y-8">
            {/* looping data/models.json */}
            {data.allMdx.edges[0].node.frontmatter.featuredmodels.map(
              individualmodel => {
                return (
                  <div className="">
                    <GatsbyImage
                      image={getImage(individualmodel.img)}
                      alt={individualmodel.model}
                    />
                    <h3 className="font-bold mt-3">{individualmodel.model}</h3>
                    <p className="">{individualmodel.desc}</p>
                    <p className="italic">{individualmodel.price}</p>
                  </div>
                )
              }
            )}
          </div>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 my-8">
            <Link
              to="/register/"
              data-gtm-category="Offers_Suntec_Featured Models"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Register your interest"
            >
              <BtnPrimary cta="Register your interest" />
            </Link>
          </div>
          <p className="mt-6 text-sm">
            *Offers are on selected models only and inclusive of applicable
            discounts and relevant benefits. Offers are on a first-come,
            first-served basis, while stocks last. Effective offers differ from
            model to model and are subject to in-house trade-in, loan and
            insurance conditions. Car specifications may vary from the models
            shown. Options and features are model-dependent. This page provides
            information on BMW products and does not constitute an offer of
            sale. Any sale is subject to our applicable terms and conditions.
          </p>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "suntec" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            featuredmodels {
              model
              desc
              price
              img {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 150 }
                    placeholder: BLURRED
                    breakpoints: [650]
                    outputPixelDensities: [2]
                  )
                }
              }
            }
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage

const seoDetails = {
  title: "Eurokars Auto | BMW Roadshow at Suntec",
  description:
    "As Singapore’s newest authorised BMW dealer, we are thrilled to bring you an exciting line-up of BMW cars at Suntec City – West Atrium.",
  keywords:
    "Eurokars, Eurokars Auto, BMW Savings, Latest BMW Deals, BMW Deals, Suntec, BMW Roadshow at Suntec",
  image: "og-eka-suntec.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
